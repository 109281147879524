"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var matcher = /.+\@.+\..+/;
function isEmail(string) {
  return matcher.test(string);
}
exports.default = isEmail;
