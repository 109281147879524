"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Delete = exports.Screen = exports.Page = exports.Track = exports.Identify = exports.Group = exports.Alias = exports.Facade = void 0;
var facade_1 = require("./facade");
Object.defineProperty(exports, "Facade", {
  enumerable: true,
  get: function () {
    return facade_1.Facade;
  }
});
var alias_1 = require("./alias");
Object.defineProperty(exports, "Alias", {
  enumerable: true,
  get: function () {
    return alias_1.Alias;
  }
});
var group_1 = require("./group");
Object.defineProperty(exports, "Group", {
  enumerable: true,
  get: function () {
    return group_1.Group;
  }
});
var identify_1 = require("./identify");
Object.defineProperty(exports, "Identify", {
  enumerable: true,
  get: function () {
    return identify_1.Identify;
  }
});
var track_1 = require("./track");
Object.defineProperty(exports, "Track", {
  enumerable: true,
  get: function () {
    return track_1.Track;
  }
});
var page_1 = require("./page");
Object.defineProperty(exports, "Page", {
  enumerable: true,
  get: function () {
    return page_1.Page;
  }
});
var screen_1 = require("./screen");
Object.defineProperty(exports, "Screen", {
  enumerable: true,
  get: function () {
    return screen_1.Screen;
  }
});
var delete_1 = require("./delete");
Object.defineProperty(exports, "Delete", {
  enumerable: true,
  get: function () {
    return delete_1.Delete;
  }
});
exports.default = __assign(__assign({}, facade_1.Facade), {
  Alias: alias_1.Alias,
  Group: group_1.Group,
  Identify: identify_1.Identify,
  Track: track_1.Track,
  Page: page_1.Page,
  Screen: screen_1.Screen,
  Delete: delete_1.Delete
});
