import { __assign } from "tslib";
var CoreLogger = /** @class */function () {
  function CoreLogger() {
    this._logs = [];
  }
  CoreLogger.prototype.log = function (level, message, extras) {
    var time = new Date();
    this._logs.push({
      level: level,
      message: message,
      time: time,
      extras: extras
    });
  };
  Object.defineProperty(CoreLogger.prototype, "logs", {
    get: function () {
      return this._logs;
    },
    enumerable: false,
    configurable: true
  });
  CoreLogger.prototype.flush = function () {
    if (this.logs.length > 1) {
      var formatted = this._logs.reduce(function (logs, log) {
        var _a;
        var _b, _c;
        var line = __assign(__assign({}, log), {
          json: JSON.stringify(log.extras, null, ' '),
          extras: log.extras
        });
        delete line['time'];
        var key = (_c = (_b = log.time) === null || _b === void 0 ? void 0 : _b.toISOString()) !== null && _c !== void 0 ? _c : '';
        if (logs[key]) {
          key = "".concat(key, "-").concat(Math.random());
        }
        return __assign(__assign({}, logs), (_a = {}, _a[key] = line, _a));
      }, {});
      // ie doesn't like console.table
      if (console.table) {
        console.table(formatted);
      } else {
        console.log(formatted);
      }
    } else {
      this.logs.forEach(function (logEntry) {
        var level = logEntry.level,
          message = logEntry.message,
          extras = logEntry.extras;
        if (level === 'info' || level === 'debug') {
          console.log(message, extras !== null && extras !== void 0 ? extras : '');
        } else {
          console[level](message, extras !== null && extras !== void 0 ? extras : '');
        }
      });
    }
    this._logs = [];
  };
  return CoreLogger;
}();
export { CoreLogger };
