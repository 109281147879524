import { __assign, __extends } from "tslib";
var compactMetricType = function (type) {
  var enums = {
    gauge: 'g',
    counter: 'c'
  };
  return enums[type];
};
var CoreStats = /** @class */function () {
  function CoreStats() {
    this.metrics = [];
  }
  CoreStats.prototype.increment = function (metric, by, tags) {
    if (by === void 0) {
      by = 1;
    }
    this.metrics.push({
      metric: metric,
      value: by,
      tags: tags !== null && tags !== void 0 ? tags : [],
      type: 'counter',
      timestamp: Date.now()
    });
  };
  CoreStats.prototype.gauge = function (metric, value, tags) {
    this.metrics.push({
      metric: metric,
      value: value,
      tags: tags !== null && tags !== void 0 ? tags : [],
      type: 'gauge',
      timestamp: Date.now()
    });
  };
  CoreStats.prototype.flush = function () {
    var formatted = this.metrics.map(function (m) {
      return __assign(__assign({}, m), {
        tags: m.tags.join(',')
      });
    });
    // ie doesn't like console.table
    if (console.table) {
      console.table(formatted);
    } else {
      console.log(formatted);
    }
    this.metrics = [];
  };
  /**
   * compact keys for smaller payload
   */
  CoreStats.prototype.serialize = function () {
    return this.metrics.map(function (m) {
      return {
        m: m.metric,
        v: m.value,
        t: m.tags,
        k: compactMetricType(m.type),
        e: m.timestamp
      };
    });
  };
  return CoreStats;
}();
export { CoreStats };
var NullStats = /** @class */function (_super) {
  __extends(NullStats, _super);
  function NullStats() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  NullStats.prototype.gauge = function () {
    var _args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      _args[_i] = arguments[_i];
    }
  };
  NullStats.prototype.increment = function () {
    var _args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      _args[_i] = arguments[_i];
    }
  };
  NullStats.prototype.flush = function () {
    var _args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      _args[_i] = arguments[_i];
    }
  };
  NullStats.prototype.serialize = function () {
    var _args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      _args[_i] = arguments[_i];
    }
    return [];
  };
  return NullStats;
}(CoreStats);
export { NullStats };
