"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var disabled = {
  Salesforce: true
};
function default_1(integration) {
  return !disabled[integration];
}
exports.default = default_1;
